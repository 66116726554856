


























import {
  computed,
  defineComponent,
  inject,
  toRefs,
  unref,
  watch
} from "@vue/composition-api";
import { extractString } from "@/helpers";

export default defineComponent({
  props: {
    outsideLabel: {
      type: Boolean,
      default: true
    },
    stackLabel: {
      type: Boolean,
      default: true
    },
    labelClass: {
      type: String,
      default: "tw-text-sm tw-font-bold"
    },
    label: String,
    isDisable: Boolean,
    subLabel: String,
    spaceY: {
      type: String,
      default: "tw-space-y-1"
    },
    fieldAttrs: {
      type: Object,
      default: () => ({})
    },
    width: String,
    maxWidth: String,
    minWidth: String
  },
  setup(props, { attrs }) {
    const { outsideLabel, label } = props;
    const isFormDisabled = <boolean>inject("isFormDisabled", false);
    const responseErrors = <Record<string, any>>inject("responseErrors", {});
    const onClearInputError = <Function>inject("onClearInputError", () => {});
    const { name = "" } = <Record<string, any>>toRefs(attrs) || {};
    const { fieldAttrs } = toRefs(props);

    // get the specific error for this field
    const fieldErrors = computed<string>(() => {
      return extractString(unref(responseErrors)?.[unref(name)]);
    });

    const getInputAttrs = (errors: string[]) => {
      const defaultAttrs = {
        // this includes input with rectangular shape
        "input-field": { outlined: true, filled: unref(isFormDisabled) },
        dropdown: { appendIcon: "mdi-chevron-down" }
      };

      return (types: string[] | string) => {
        let multipleTypesAttrs = {};

        if (Array.isArray(types)) {
          types.forEach(type => {
            multipleTypesAttrs = {
              ...multipleTypesAttrs,
              ...defaultAttrs[type]
            };
          });
        }

        return {
          errorMessages: [...errors, unref(fieldErrors)]?.[0] || null,
          label: outsideLabel ? null : label,
          ...(Array.isArray(types) ? multipleTypesAttrs : defaultAttrs[types])
        };
      };
    };

    return {
      getInputAttrs,
      fieldErrors,
      name,
      responseErrors,
      onClearInputError
    };
  }
});
