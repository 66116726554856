<template>
  <v-card class="enstack-shadow rounded-lg" v-bind="$attrs">
    <slot />
    <v-overlay :value="loading" color="white" absolute>
      <v-progress-circular indeterminate size="64" width="8" color="info" />
    </v-overlay>
  </v-card>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: { loading: Boolean }
});
</script>
