


















import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    color: String,
    textColor: String,
    label: String
  }
});
