import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";

import { loadPlugins, registerGlobalComponents } from "./helpers";
import vuetify from "./plugins/vuetify.js";

import "nprogress/nprogress.css";

loadPlugins([
  "vue-composition-api",
  "vee-validate",
  "v-calendar",
  "v-currency"
]);
registerGlobalComponents(Vue);

import "@fontsource/montserrat";

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
