








import { defineComponent } from "@vue/composition-api";
import InlineSvg from "vue-inline-svg";

export default defineComponent({
  components: { InlineSvg },
  props: {
    name: {
      type: String,
      required: true
    }
  }
});
