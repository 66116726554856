var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-navigation-drawer',{staticClass:"tw-text-white tw-py-7 tw-flex tw-flex-col",attrs:{"app":"","color":"primary"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('div',{staticClass:"tw-space-y-10"},[_c('router-link',{attrs:{"to":"/web/admin"}},[_c('div',{staticClass:"tw-flex tw-justify-center tw-space-x-4"},[_c('div',{staticClass:"tw-h-10"},[_c('v-img',{staticClass:"tw-h-full",attrs:{"src":require('@/assets/img/enstack-logo.svg'),"transition":false}})],1),_c('div',{staticClass:"tw-flex tw-flex-col tw-items-end tw-mt-2.5 tw-w-[100px]"},[_c('div',{staticClass:"tw-w-full"},[_c('v-img',{staticClass:"tw-w-full",attrs:{"src":require('@/assets/img/enstack-name.svg'),"transition":false}})],1),_c('span',{staticClass:"tw-uppercase tw-font-bold tw-text-sm tw-text-white"},[_vm._v(" Admin ")])])])]),_vm._l((_vm.navItems),function(nav){return _c('v-list',{key:nav.subheader,staticClass:"tw-space-y-4 tw-font-medium tw-px-3",attrs:{"link":""}},[_c('v-subheader',{staticClass:"white--text tw-text-[22px] tw-px-6",domProps:{"textContent":_vm._s(nav.subheader)}}),_c('v-list-item-group',[_vm._l((nav.items),function(item){return [(item.text)?_c('div',{key:item.text},[_c('v-list-item',{staticClass:"tw-px-6 tw-rounded-lg tw-font-normal",class:[
                  {
                    'tw-bg-[#0B0A44]': item.link === _vm.$route.name
                  },
                  (item.children || []).length ? 'tw-mb-2' : 'tw-mb-4'
                ],attrs:{"ripple":false,"to":{ name: item.link }}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"tw-text-white",class:{ 'tw-font-bold': item.text === _vm.$route.name },domProps:{"textContent":_vm._s(item.text)}})],1)],1),_vm._l((item.children),function(child){return _c('v-list-item',{key:child.text,staticClass:"tw-px-6 tw-rounded-lg tw-font-normal tw-mb-4 tw-ml-4",class:{
                  'tw-bg-[#0B0A44]': child.link === _vm.$route.name
                },attrs:{"ripple":false,"to":{ name: child.link }}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"tw-text-white",class:{ 'tw-font-bold': child.text === _vm.$route.name },domProps:{"textContent":_vm._s(child.text)}})],1)],1)})],2):_vm._e()]})],2)],1)})],2)]),_c('v-app-bar',{staticClass:"tw-px-1 md:tw-px-5",attrs:{"app":"","height":"84","absolute":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',{attrs:{"color":"enstack-black","size":"28"},domProps:{"textContent":_vm._s('mdi-menu')}})],1),_c('v-spacer'),_c('v-toolbar-title',{staticClass:"tw-text-base tw-text-enstack-black"},[_vm._v("Admin")])],1),_c('v-main',[_c('div',{staticClass:"tw-px-5 md:tw-px-9 tw-py-9"},[(_vm.breadcrumbItems.length > 1 && _vm.isManualTransactionEnabled)?_c('div',{staticClass:"tw-mb-5 tw-flex tw-flex-row"},[_c('span',{staticClass:"tw-whitespace-nowrap"},[_c('span',{staticClass:"tw-text-2xl tw-font-bold",domProps:{"textContent":_vm._s(_vm.currentPageName)}}),_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbItems,"large":"","dense":""}})],1),(_vm.currentPageName === 'Manual Transactions')?_c('span',{staticClass:"tw-w-full tw-flex tw-justify-end tw-mt-8 tw-mr-16"},[_c('transactions-modal')],1):_vm._e()]):(_vm.breadcrumbItems.length > 1)?_c('div',{staticClass:"tw-mb-5"},[_c('span',{staticClass:"tw-text-2xl tw-font-bold",domProps:{"textContent":_vm._s(_vm.currentPageName)}}),_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbItems,"large":"","dense":""}})],1):_vm._e(),_c('router-view')],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }