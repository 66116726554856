





















import {
  defineComponent,
  onMounted,
  ref,
  toRefs,
  nextTick,
  unref
} from "@vue/composition-api";

export default defineComponent({
  props: {
    immediate: Boolean,
    loading: Boolean,
    persistent: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const { immediate, persistent } = toRefs(props);
    const isShow = ref<boolean>(false);
    const resolveFn = ref<Function | null>(null);

    const close = (event: string) => {
      if (["clickOutside", "pressEsc"].includes(event) && unref(persistent))
        return;

      hide();
    };

    const show = () => {
      isShow.value = true;
      return new Promise(resolve => (resolveFn.value = resolve));
    };

    const hide = () => {
      emit("close-modal");
      isShow.value = false;
    };

    const confirm = () => {
      resolveFn.value?.(true);
      isShow.value = false;
    };

    const cancel = () => {
      resolveFn.value?.(false);
      isShow.value = false;
    };

    onMounted(async () => {
      if (unref(immediate)) {
        await nextTick();
        show();
      }
    });

    return { isShow, close, show, hide, confirm, cancel };
  }
});
