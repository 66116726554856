import Vue from "vue";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  max_value,
  min_value,
  alpha_num,
  integer
} from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "This field is required.",
  validate(value) {
    return {
      required: true,
      valid: Array.isArray(value)
        ? value.length
        : ["", null, undefined].indexOf(value) === -1
    };
  }
});
extend('url', {
  validate: (value) => {
    if (value) {
      return /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
        value
      )
    }
    return false
  },
})
extend("max_value", {
  ...max_value,
  params: ["max"],
  message: "This field must be less than or equal to {max}."
});
extend("min_value", {
  ...min_value,
  params: ["min"],
  message: "This field must be greater than or equal to {min}."
});
extend("alpha_num", {
  ...alpha_num,
  message: "This field must only have letters or numbers."
});
extend("integer", {
  ...integer,
  message: "This field must only have whole number as value."
});
extend("positive", {
  message: "This field must be greater than zero.",
  validate(value) {
    return value > 0;
  }
});
extend("whole_number", {
  message: "This field must be greater than or equal to zero.",
  validate(value) {
    return value >= 0;
  }
});
extend("required_condition", {
  ...required,
  message: "Please set the voucher condition."
});

extend("required_voucher_conditions", {
  ...required,
  message: "Please set the voucher condition.",
  validate(value) {
    return {
      required: true,
      valid:
        !!value.length &&
        !!(value[0]?.shipping_method || value[0]?.payment_method)
    };
  }
});

extend("required_shipping", {
  message: "Please set shipping option.",
  validate(value) {
    return (
      !value.payment_method || !!(value.payment_method && value.shipping_method)
    );
  }
});

extend("required_payment", {
  message: "Please set payment method for this shipping option.",
  validate(value) {
    return (
      !value.shipping_method ||
      !!(value.shipping_method && value.payment_method)
    );
  }
});

extend(
  "unique_voucher_condition",
  {
    message: "Duplicate conditions.",
    validate(value, args) {
      const [data] = args;

      return (
        !value.shipping_method ||
        !value.payment_method ||
        data
          .map(
            condition =>
              `${condition.shipping_method}-${condition.payment_method}`
          )
          .filter(
            condition =>
              condition === `${value.shipping_method}-${value.payment_method}`
          ).length == 1
      );
    }
  },
  { paramNames: ["data"] }
);

extend("empty_voucher_condition", {
  message: "Please set the voucher condition.",
  validate(value) {
    return (
      !!(value.payment_method && value.shipping_method)
    );
  }
});


// Register it globally
Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
