


































import { defineComponent } from "@vue/composition-api";
import reusableComponent from "@/composables/reusableInputComponent";

const { props, ...options } = reusableComponent;

export default defineComponent({
  ...options,
  props: {
    ...props,
    appendIcon: {
      type: String,
      default: "mdi-chevron-down"
    }
  }
});
