




































import { defineComponent } from "@vue/composition-api";
import reusableComponent from "@/composables/reusableInputComponent";
export default defineComponent({ ...reusableComponent });
