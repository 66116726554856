import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

import { TIMEZONE } from "../constants/appConstants";

dayjs.extend(utc);
dayjs.extend(timezone);

export const format = (date: string, format: string) => {
  return dayjs(date).tz(TIMEZONE).format(format);
};

export const parse = (date: string, format: string) => {
  return dayjs.tz(date, format, TIMEZONE).utc().format()
};
