



























import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  props: {
    showBulkTransaction: Boolean,
    showSummary: Boolean,
    formData: {
      type: Object,
      default(rawData) {
        return { ...rawData }
      }
    },
    formDataFile: {
      type: Object,
      default(rawData) {
        return { ...rawData }
      }
    },
  },
  setup() {
    return {}
  },
})
