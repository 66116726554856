








import {
  computed,
  defineComponent,
  provide,
  ref,
  toRefs,
  unref
} from "@vue/composition-api";
import { ValidationObserver } from "vee-validate";

type Validator = InstanceType<typeof ValidationObserver>;

export default defineComponent({
  props: { value: null },
  setup(props, { attrs, emit }) {
    const { disabled } = toRefs(attrs);
    const { value = null } = toRefs(props);

    // model for errors
    const formErrors = computed<any>({
      get() {
        return unref(value) || {};
      },
      set(newValue) {
        emit("input", newValue);
      }
    });

    // remove the custom errors (the error from API response)
    // when user input some value
    const onClearInputError = (fieldName: string) => {
      if (unref(formErrors)[fieldName]) delete formErrors.value[fieldName];
    };

    provide("isFormDisabled", disabled);

    // errors from an API response
    provide("responseErrors", value);

    provide("onClearInputError", onClearInputError);

    const validator = ref<Validator>();
    const validateForm = async (validator: any): Promise<boolean> =>
      !!unref(validator) && (await unref(validator).validate());

    const onSubmit = async (fn: Function, ...args: any[]) => {
      if (attrs.hasOwnProperty("disabled") && attrs.disabled) return;

      const isValid = await validateForm(validator);

      if (!isValid) return;

      fn(...args);
    };

    const hideError = (obj: Record<string, string>, key: string) => {
      if (obj[key]) delete obj[key];
    };

    const resetError = () => unref(validator)?.reset();

    return { validator, onSubmit, hideError, resetError };
  }
});
