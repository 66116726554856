export default async (fn, ...args) => {
  try {
    const response = await fn(...args);
    return { data: response.data, errors: null };
  } catch (errors) {
    return {
      data: null,
      errors: errors?.response?.data || errors?.response || errors
    };
  }
};
