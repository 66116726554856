











import { defineComponent, watch } from "@vue/composition-api";
import { extractString } from "@/helpers";

export default defineComponent({
  props: {
    value: null,
    errorMessages: [String, Array]
  },
  setup(props, { emit }) {
    watch(
      () => props.value,
      curr => {
        emit("input", curr);
      },
      { deep: true }
    );

    return { extractString };
  }
});
