










































import { defineComponent, ref, watch, nextTick } from "@vue/composition-api";
import reusableComponent from "@/composables/reusableInputComponent";
import { VSelect } from "vuetify/lib/components/VSelect/";

const { props, ...options } = reusableComponent;

export default defineComponent({
  ...options,
  props: {
    ...props,
    appendIcon: {
      type: String,
      default: "mdi-chevron-down"
    }
  },
  setup() {
    const select = ref<InstanceType<typeof VSelect> | {}>({});

    const selectWidth = ref<number>(0);

    watch(select, async (val: VSelect) => {
      await nextTick();
      selectWidth.value = val.$el.clientWidth;
    });

    return { select, selectWidth };
  }
});
