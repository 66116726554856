
























































































































import {
  computed,
  defineComponent,
  ref,
  toRefs,
  unref
} from "@vue/composition-api";
import reusableComponent from "@/composables/reusableInputComponent";
import { get } from "lodash";
import {
  DATE_FORMAT_INPUT,
  TIME_FORMAT_INPUT,
  DATETIME_FORMAT_INPUT
} from "@/constants/formatConstants";

const { props, ...options } = reusableComponent;

export default defineComponent({
  ...options,
  props: {
    ...props,
    mode: {
      type: String,
      default: "date",
      validator: (value: string) =>
        ["date", "date-time", "time"].includes(value)
    },
    labelPrefix: {
      type: [Array, String],
      default: () => ""
    },
    value: null
  },
  setup(props, { emit }) {
    const { mode, value } = toRefs(props);
    const dateTime = ref<Date | string>("");
    const modelFormats = <Record<string, string>>{
      "date-time": DATETIME_FORMAT_INPUT,
      date: DATE_FORMAT_INPUT,
      time: TIME_FORMAT_INPUT
    };
    const modelConfig = {
      type: "string",
      mask: modelFormats[unref(mode)]
    };

    const dateTimeData = computed({
      get() {
        return unref(value);
      },
      set(currentValue) {
        emit("input", currentValue);
      }
    });

    const onClear = (type: string) => {
      if (type !== "time" || unref(mode) === "time") dateTimeData.value = "";
    };

    const getListeners = (obj: any) => {
      const { input, change, ...event } = obj;
      return event;
    };

    return {
      dateTime,
      modelConfig,
      onClear,
      getListeners,
      dateTimeData,
      get
    };
  }
});
