var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._t("default",null,{"showModal":_vm.showModal}),_c('app-modal',_vm._g(_vm._b({ref:"dialog"},'app-modal',_vm.$attrs,false),_vm.$listeners),[_c('div',{class:("tw-enstack-black tw-space-y-" + _vm.contentSpace)},[_c('div',{staticClass:"tw-space-y-2"},[(_vm.dialogTitle || _vm.$scopedSlots.title || _vm.$slots.title)?[_vm._t("title",function(){return [_c('span',{staticClass:"tw-block tw-font-bold tw-text-xl",class:("text-" + _vm.alignTitle + " " + _vm.titleColor + "--text"),domProps:{"textContent":_vm._s(_vm.dialogTitle)}})]},{"hideDialog":_vm.hideDialog})]:_vm._e(),(_vm.dialogContent || _vm.$scopedSlots.content || _vm.$slots.content)?[_vm._t("content",function(){return [_c('p',{class:("text-" + _vm.alignContent + " " + _vm.contentColor + "--text"),domProps:{"textContent":_vm._s(_vm.dialogContent)}})]})]:_vm._e()],2),(!_vm.noActions || _vm.get(_vm.DIALOG_TYPES[_vm.type], 'noActions', false))?[_vm._t("actions",function(){return [_c('div',{staticClass:"tw-flex tw-w-full tw-space-x-4"},[_c('app-button',_vm._b({class:(_vm.auto && _vm.confirmOnly) ||
                (_vm.get(_vm.DIALOG_TYPES[_vm.type], 'confirmOnly', false) &&
                  _vm.get(_vm.DIALOG_TYPES[_vm.type], 'auto', false))
                  ? 'tw-mx-auto'
                  : 'tw-flex-grow',on:{"click":_vm.onConfirm}},'app-button',
                Object.assign(
                  {},
                  _vm.get(_vm.DIALOG_TYPES[_vm.type], 'confirmAttrs', {}),
                  _vm.confirmAttrs
                )
              ,false),[_vm._t("confirm-label",function(){return [_c('span',{domProps:{"textContent":_vm._s(
                    _vm.get(_vm.DIALOG_TYPES[_vm.type], 'confirmAttrs.label', '') ||
                    _vm.confirmAttrs.label ||
                    'Confirm'
                  )}})]})],2),(
                !_vm.confirmOnly || _vm.get(_vm.DIALOG_TYPES[_vm.type], 'confirmOnly', false)
              )?_c('app-button',_vm._b({staticClass:"tw-flex-grow",attrs:{"outlined":""},on:{"click":_vm.onCancel}},'app-button',
                Object.assign(
                  {},
                  _vm.get(_vm.DIALOG_TYPES[_vm.type], 'cancelAttrs', {}),
                  _vm.cancelAttrs
                )
              ,false),[_vm._t("cancel-label",function(){return [_c('span',{domProps:{"textContent":_vm._s(
                    _vm.get(_vm.DIALOG_TYPES[_vm.type], 'cancelAttrs.label', '') ||
                    _vm.cancelAttrs.label ||
                    'Cancel'
                  )}})]})],2):_vm._e()],1)]},{"onCancel":_vm.onCancel,"onConfirm":_vm.onConfirm})]:_vm._e()],2)])],2)}
var staticRenderFns = []

export { render, staticRenderFns }