import { PropType } from "@vue/composition-api";
import { AppInput } from "@/components/Common";
import { InputProps } from "@/types/components/InputComponents";

export default {
  components: { AppInput },
  props: {
    inputAttrs: {
      type: Object as PropType<InputProps>,
      default: () => ({ outsideLabel: true })
    },
    isDisable: Boolean,
    value: null,
    validationRules: Object,
    hideDetails: { type: [String, Boolean], default: "auto" },
    prependInnerIcon: {
      type: String,
      default: ''
    },
    customClass: {
      type: String,
      default: ''
    }
  }
};
