import axios from "axios";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

// Default config for the axios instance

const getToken = () => localStorage.getItem("token");

const axiosParams = {
  baseURL: process.env.VUE_APP_API_URL,
  headers: {}
};

const token = getToken();
if (token) axiosParams.headers.Authorization = `Token ${token}`;

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

// Create axios instance with default params
const axiosInstance = axios.create(axiosParams);
// Main api function

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    NProgress.start();
    NProgress.set(0.1);
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  function (response) {
    NProgress.done();
    return response;
  },
  function (error) {
    NProgress.done();
    return Promise.reject(error);
  }
);

const api = axios => {
  // Wrapper functions around axios

  return {
    get: (url, config) => axios.get(url, config),
    post: (url, body, config) => axios.post(url, body, config),
    patch: (url, body, config) => axios.patch(url, body, config),
    put: (url, body, config) => axios.put(url, body, config),
    delete: (url, config) => axios.delete(url, config)
  };
};
// Initialise the api function and pass axiosInstance to it
export default api(axiosInstance);
