export const formatToCurrency = (amount: number) => {
  return +amount || typeof amount === "number"
    ? amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,")
    : amount;
};

export const formatNumberToCurrency = (
  num: number,
  currency: string = "PHP"
) => {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    currency
  }).format(num);
};
