import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import "@mdi/font/css/materialdesignicons.css";
import { ENSTACK_COLORS } from "@/constants/colorConstants";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    icons: {
      iconfont: "mdi"
    },
    themes: {
      light: {
        primary: "#141379",
        secondary: "#009FE0",
        accent: "#82B1FF",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        ...ENSTACK_COLORS
      }
    }
  }
});
