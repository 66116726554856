







































import { defineComponent, computed,  } from "@vue/composition-api";
import { AppInput } from "@/components/Common";

export default defineComponent({
  components: { AppInput },
  props: {
    showSingleTransaction: Boolean,
    showSummary: Boolean,
    formData: {
      type: Object,
      default(rawData) {
        return { ...rawData }
      }
    },
  },
  setup(props) {
    const singleTransactionForms = computed(() => 
    [
      {
        name: "user",
        inputType: "app-input-text-field",
        attrs: { 
          label: "UserID:",
          type: "number", 
          class: "input-field tw-w-full" 
        },
        validationRules: {
          positive: true,
        }
      },
      {
        name: "amount",
        inputType: "app-input-currency-field",
        attrs: { 
          label: "Amount:", 
          allowNegative: true, 
          class: "input-field tw-w-full" 
        }
      },
      {
        name: "description",
        inputType: "app-input-text-field",
        attrs: { 
          label: "Description:", 
          class: "input-field tw-w-full" 
        }
      },
    ])

    return { 
      singleTransactionForms
    };
  }
});
