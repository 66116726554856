var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-space-y-8"},[_c('app-card',{staticClass:"py-6 tw-space-y-6"},[_c('div',[_c('div',{staticClass:"tw-flex tw-px-5 tw-flex-col md:tw-flex-row md:tw-px-7 md:tw-space-y-0",style:({ display: _vm.hasGap })},[_c('div',{staticClass:"tw-flex tw-flex-wrap tw-flex-col md:tw-flex-row tw-mb-3 md:tw-space-x-7"},[_c('app-input-text-field',_vm._b({style:({ visibility: _vm.hasSearch }),scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('app-svg-icon',{staticClass:"tw-mr-2",attrs:{"name":"search","height":"22","width":"20","color":"#a1a1a1"}})]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},'app-input-text-field',_vm.searchAttrs,false)),_c('div',[_vm._t("top-left",null,{"refreshData":_vm.refreshData,"getTableData":_vm.getTableData})],2)],1),_c('v-spacer'),_c('div',{staticClass:"tw-flex tw-flex-col tw-space-y-4 md:tw-flex-row md:tw-px-0 md:tw-space-y-0 md:tw-space-x-7"},[(_vm.hasSelectedRows && _vm.selectRows)?_c('app-select',{attrs:{"label":"Action","input-attrs":{
              stackLabel: false,
              labelClass: 'tw-font-normal tw-opacity-50'
            },"items":[{ text: '-', value: '' } ].concat( _vm.selectedRowActions),"hide-selected":""},on:{"change":_vm.onSelectRowsAction},model:{value:(_vm.selectedRowAction),callback:function ($$v) {_vm.selectedRowAction=$$v},expression:"selectedRowAction"}}):_vm._e(),_c('div',[_vm._t("top-right",null,{"refreshData":_vm.refreshData,"getTableData":_vm.getTableData})],2)],1)],1),(_vm.$scopedSlots['header-bottom'] || _vm.$slots['header-bottom'])?_c('div',{staticClass:"tw-px-5 tw-mt-3 md:tw-px-7"},[_vm._t("header-bottom",null,{"refreshData":_vm.refreshData,"getTableData":_vm.getTableData})],2):_vm._e()]),_c('v-simple-table',{attrs:{"dense":""},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[(_vm.selectRows)?_c('th',{staticClass:"tw-align-bottom py-2"},[_c('app-checkbox',{attrs:{"value":_vm.hasSelectedRows,"indeterminate":_vm.isIndeterminate},on:{"change":_vm.onSelectAllRows}})],1):_vm._e(),_vm._l((_vm.headers),function(header){return _c('th',{key:header.name,staticClass:"tw-align-bottom py-3 font-weight-medium tw-capitalize",class:{
                'tw-cursor-pointer': header.sortable,
                'tw-text-secondary':
                  _vm.isColumnIncluded([header.name, ("-" + (header.name))]) &&
                  header.sortable
              },staticStyle:{"font-size":"14px"},attrs:{"width":header.width},on:{"click":function($event){header.sortable ? _vm.sortColumns(header.name) : null}}},[_c('div',{staticClass:"tw-inline-flex tw-items-center tw-relative"},[_c('span',{staticClass:"tw-whitespace-nowrap",domProps:{"textContent":_vm._s(header.label || header.name)}}),(header.sortable)?_c('div',{staticClass:"tw-flex tw-flex-col tw--space-y-3 tw-absolute tw--right-5"},[_c('v-icon',{attrs:{"size":"22","color":_vm.isColumnIncluded([header.name])
                        ? 'secondary'
                        : '#c1c1c1'},domProps:{"textContent":_vm._s('mdi-chevron-up')}}),_c('v-icon',{attrs:{"size":"22","color":_vm.isColumnIncluded([("-" + (header.name))])
                        ? 'secondary'
                        : '#c1c1c1'},domProps:{"textContent":_vm._s('mdi-chevron-down')}})],1):_vm._e()])])}),(_vm.$scopedSlots['actions-column'])?_c('th',{staticClass:"tw-align-bottom py-3 font-weight-medium tw-capitalize text-center",staticStyle:{"font-size":"14px"}},[_vm._v(" Action ")]):_vm._e()],2)]),_c('tbody',_vm._l((_vm.tableData),function(data){return _c('tr',{key:data[_vm.dataKey]},[(_vm.selectRows)?_c('td',{staticClass:"tw-align-top"},[_c('app-checkbox',{attrs:{"checkbox-items":data[_vm.dataKey]},model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1):_vm._e(),_vm._l((_vm.headers),function(header){return _c('td',{key:header.name,staticClass:"tw-align-top py-5 tw-text-enstack-black",class:[
                header.class,
                {
                  'tw-whitespace-nowrap': !Boolean(
                    _vm.get(header, 'contentAttrs.isWrapContent')
                  )
                }
              ]},[_vm._t("custom-table-data",function(){return [_vm._t(("col-" + (header.name)),function(){return [_c('span',{domProps:{"textContent":_vm._s(_vm.getValue(header, data))}})]},{"value":_vm.getValue(header, data),"header":header,"row":data,"refreshData":_vm.refreshData,"getTableData":_vm.getTableData})]},{"value":_vm.getValue(header, data),"header":header,"row":data,"refreshData":_vm.refreshData,"getTableData":_vm.getTableData})],2)}),(_vm.$scopedSlots['actions-column'])?_c('td',{staticClass:"tw-align-top py-5"},[_vm._t("actions-column",null,{"row":data,"refreshData":_vm.refreshData,"getTableData":_vm.getTableData})],2):_vm._e()],2)}),0)]},proxy:true}],null,true)}),(!_vm.tableData.length)?_c('div',{staticClass:"tw-text-center tw-text-enstack-light-gray tw-pb-3 tw-flex tw-items-center tw-justify-center tw-space-x-2"},[_c('v-icon',{attrs:{"size":"18"},domProps:{"textContent":_vm._s('mdi-table-alert')}}),_c('span',[_vm._v("No data available")])],1):_vm._e()],1),(_vm.pagination.totalPage > 1)?_c('v-pagination',{attrs:{"length":_vm.pagination.totalPage,"total-visible":"7"},on:{"input":function($event){return _vm.getTableData({ isResetSelectedRows: false })}},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }