



























































































import { computed, defineComponent, ref } from "@vue/composition-api";
import { AppModal } from "@/components/Common";
import { DIALOG_TYPES } from "@/constants/appConstants";
import { get } from "lodash";

const textPositions = ["left", "center", "right", "justify"];
const DIALOG_TYPES_KEYS = Object.keys(DIALOG_TYPES);

export default defineComponent({
  components: { AppModal },
  props: {
    title: String,
    alignTitle: {
      type: String,
      default: "center",
      validator: (value: string) => textPositions.includes(value)
    },
    titleColor: {
      type: String,
      default: "primary"
    },

    content: String,
    alignContent: {
      type: String,
      default: "left",
      validator: (value: string) => textPositions.includes(value)
    },
    contentColor: {
      type: String,
      default: "enstack-black"
    },
    contentSpace: {
      type: String,
      default: "10"
    },

    // Attributes for buttons
    confirmAttrs: {
      type: Object,
      default: () => ({})
    },
    cancelAttrs: {
      type: Object,
      default: () => ({})
    },
    noActions: Boolean,

    // Show one button only
    confirmOnly: Boolean,

    // Works with confirm only attrs
    // set button with to fit the content and position to center
    auto: Boolean,

    // for reference, check DIALOG_TYPES under appConstants
    type: {
      type: String,
      validator: (value: string) => DIALOG_TYPES_KEYS.includes(value)
    },

    closeOnConfirm: {
      type: Boolean,
      default: true
    },
    closeOnCancel: {
      type: Boolean,
      default: true
    }
  },
  setup({ type, title, content, closeOnConfirm, closeOnCancel }, { emit }) {
    const dialog = ref<InstanceType<typeof AppModal> | null>(null);

    const dialogTitle = computed(
      () => title || get(DIALOG_TYPES[type || ""], "title", "") || ""
    );

    const dialogContent = computed(
      () => content || get(DIALOG_TYPES[type || ""], "content", "") || ""
    );

    const showModal = (fn?: Function) => {
      if (typeof fn === "function") fn?.();
      dialog.value?.show();
    };

    const hideDialog = () => dialog.value?.hide();

    const onConfirm = () => {
      emit("confirm", hideDialog);
      if (closeOnConfirm) hideDialog();
    };

    const onCancel = () => {
      emit("cancel", hideDialog);
      if (closeOnCancel) hideDialog();
    };

    return {
      dialog,
      showModal,
      onConfirm,
      onCancel,
      DIALOG_TYPES,
      dialogTitle,
      dialogContent,
      get,
      hideDialog
    };
  }
});
