














import { defineComponent, toRefs, computed, unref } from "@vue/composition-api";

export default defineComponent({
  props: {
    value: null,
    type: {
      type: String,
      validator: (value: string) => ["error", "success", "info"].includes(value)
    },
    message: null
  },
  setup(props) {
    const { type } = toRefs(props);
    const defaultColor = <Record<string, string>>{
      error: "enstack-red",
      success: "green"
    };

    const attrs = computed(() => ({
      color: defaultColor[unref(type) || ""]
    }));
    return { attrs };
  }
});
