import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { StandardLayout } from "@/layout";

Vue.use(VueRouter);

const isAuthenticated = () => localStorage.getItem("token");

const routes: Array<RouteConfig> = [
  {
    path: "/web/admin/login/",
    name: "Login",
    component: () =>
      import(
        /* webpackChunkName: "voucher" */
        "@/views/Auth/Login.vue"
      ),
    beforeEnter: (to, from, next) => {
      if (isAuthenticated()) next({ name: "Dashboard" });
      else next();
    }
  },
  {
    path: "/web/admin",
    component: StandardLayout,
    children: [
      { path: "", name: "Dashboard" },
      {
        path: "vouchers",
        component: {
          render(h) {
            return h("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Vouchers",
            component: () =>
              import(
                /* webpackChunkName: "voucher" */
                "@/views/Vouchers/Vouchers.vue"
              )
          },
          {
            path: "add",
            name: "Add Voucher",
            meta: {
              parents: [
                { text: "Vouchers", to: { name: "Vouchers" }, exact: true }
              ]
            },
            component: () =>
              import(
                /* webpackChunkName: "voucher" */
                "@/views/Vouchers/VouchersAddEdit.vue"
              )
          },
          {
            path: ":id",
            name: "Edit Voucher",
            meta: {
              parents: [
                { text: "Vouchers", to: { name: "Vouchers" }, exact: true }
              ]
            },
            component: () =>
              import(
                /* webpackChunkName: "voucher" */
                "@/views/Vouchers/VouchersAddEdit.vue"
              )
          }
        ]
      },
      {
        path: "cms",
        component: {
          render(h) {
            return h("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "CMS",
            component: () => import("@/views/CMS/CMS.vue")
          },
          {
            path: "add-banner",
            name: "Add Banner",
            meta: {
              parents: [
                { text: "CMS", to: { name: "Add Banner" }, exact: true }
              ]
            },
            component: () => import("@/views/CMS/banners/BannerAddEdit.vue")
          },
          {
            path: "edit-banner/:id",
            name: "Edit Banner",
            meta: {
              parents: [
                { text: "CMS", to: { name: "Edit Banner" }, exact: true }
              ]
            },
            component: () => import("@/views/CMS/banners/BannerAddEdit.vue")
          }
        ]
      },
      {
        path: "buyers",
        component: {
          render(h) {
            return h("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Buyers",
            component: () =>
              import(
                /* webpackChunkName: "buyer" */
                "@/views/Buyers/Buyers.vue"
              )
          },
          {
            path: ":id",
            name: "View Buyer",
            meta: {
              parents: [{ text: "Buyers", to: { name: "Buyers" }, exact: true }]
            },
            component: () => import("@/views/Buyers/ViewABuyer.vue")
          }
        ]
      },
      {
        path: "transfers",
        component: {
          render(h) {
            return h("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Transfers",
            component: () =>
              import(
                /* webpackChunkName: "transfers" */
                "@/views/Transfers/Transfers.vue"
              )
          },
          {
            path: "settings",
            meta: {
              title: "Settings",
              parents: [
                { text: "Transfers", to: { name: "Transfers" }, exact: true }
              ]
            },
            name: "Transfer Settings",
            component: () =>
              import(
                /* webpackChunkName: "transfers" */
                "@/views/Transfers/TransfersSettings.vue"
              )
          }
        ]
      },
      {
        path: "manualTransactions",
        component: {
          render(h) {
            return h("router-view");
          }
        },
        children: [
          {
            path: "",
            name: "Manual Transactions",
            component: () =>
              import(
                "@/views/Transactions/Transactions.vue"
              )
          },
        ]
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  routes
});

router.beforeEach((to, from, next) => {
  if (to.name !== "Login" && !isAuthenticated()) next({ name: "Login" });
  else next();
});

export default router;
