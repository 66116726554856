export const ENSTACK_COLORS: Record<string, string> = {
  "enstack-black": "#636363",

  "enstack-light-gray": "#788995",
  "enstack-gray": "#838383",
  "enstack-gray-1": "#BDBDBD",
  "enstack-gray-2": "#FAFAFA",
  "enstack-gray-3": "#D3D3D3",

  "enstack-green": "#2ED880",
  "enstack-yellow": "#FDCE01",
  "enstack-red": "#FF3F40"
};
