














































import {
  computed,
  defineComponent,
  PropType,
  toRefs,
  unref
} from "@vue/composition-api";
import reusableComponent from "@/composables/reusableInputComponent";
import { CheckboxItems } from "@/types/components/InputComponents";

const { props, ...options } = reusableComponent;

export default defineComponent({
  ...options,
  props: {
    ...props,
    items: {
      type: Array as PropType<Array<CheckboxItems>>,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { value } = toRefs(props);

    const selected = computed({
      get: () => unref(value),
      set: newValue => emit("input", newValue)
    });

    return { selected };
  }
});
