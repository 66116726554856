


































































































































import { defineComponent, ref, computed, unref, inject } from "@vue/composition-api";
import { Route } from "vue-router";
import TransactionsModal from "@/views/Transactions/components/TransactionsModal.vue";

export default defineComponent({
  components: { TransactionsModal },
  setup(_, { root }) {
    const drawer = ref<boolean>(true);
    const $route = computed<Route | null>(() => root?.$route || {});
    const { unleash } = inject('unleash') || {};
    const isManualTransactionEnabled = computed(() =>
      unleash.isEnabled("MANUAL_TRANSACTION_UI")
    );
    const navItems = [
      {
        subheader: "Menu",
        items: [
          {
            text: "CMS",
            link: "CMS",
            // children: [
            //   { text: "Banner", link: "" },
            //   { text: "Banner Logs", link: "" }
            // ]
          },
          { text: "Vouchers", link: "Vouchers" },
          {
            text: "Transfers",
            link: "Transfers",
            children: [{ text: "Settings", link: "Transfer Settings" }]
          },
          { text: "Buyers", link: "Buyers" },
          isManualTransactionEnabled 
            ? { text: "Manual Transactions", link: "Manual Transactions" } 
            : {}
        ]
      }
    ];
    
    const currentPageName = computed(() => {
      const { meta = {}, name = "" } = unref($route) || {};
      return meta?.title || name;
    });

    const breadcrumbItems = computed(() => {
      const { meta = {}, params = {} } = unref($route) || {};

      const breadcrumbs = [
        { text: "Admin", to: { name: "Dashboard" }, exact: true },
        ...(meta?.parents || [])
      ];

      if (unref($route)?.name !== breadcrumbs[0].to.name) {
        breadcrumbs.push({
          text: params?.id || unref(currentPageName),
          disabled: true
        });
      }

      return breadcrumbs;
    });

    return { drawer, navItems, breadcrumbItems, currentPageName, isManualTransactionEnabled };
  }
});
