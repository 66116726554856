import { render, staticRenderFns } from "./TransactionsModal.vue?vue&type=template&id=5950d93e&scoped=true&"
import script from "./TransactionsModal.vue?vue&type=script&lang=ts&"
export * from "./TransactionsModal.vue?vue&type=script&lang=ts&"
import style0 from "./TransactionsModal.vue?vue&type=style&index=0&id=5950d93e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5950d93e",
  null
  
)

export default component.exports