




























































import {
  computed,
  defineComponent,
  ref,
  toRefs,
  unref
} from "@vue/composition-api";
import reusableComponent from "@/composables/reusableInputComponent";
import { get } from "lodash";
import { DATE_FORMAT_INPUT } from "@/constants/formatConstants";

const { props, ...options } = reusableComponent;

export default defineComponent({
  ...options,
  props: {
    ...props,
    mode: {
      type: String,
      default: "date",
      validator: (value: string) =>
        ["date", "date-time", "time"].includes(value)
    },
    labelPrefix: {
      type: [Array, String],
      default: () => ""
    },
    value: null
  },
  setup(props, { emit }) {
    const { value } = toRefs(props);
    const dateTime = ref<Date | string>("");

    const modelConfig = {
      type: "string",
      mask: DATE_FORMAT_INPUT
    };

    const dateRangeData = computed({
      get() {
        return unref(value);
      },
      set(currentValue: { start: string; end: string }) {
        emit("input", setDateFormat(currentValue, ","));
      }
    });

    const setDateFormat = (value: any, separator: string = "-") => {
      const { start = "", end = "" } = value || {};
      return [start, end].filter(Boolean).join(separator);
    };

    const getListeners = (obj: any) => {
      const { input, change, ...event } = obj;
      return event;
    };

    return {
      dateTime,
      modelConfig,
      getListeners,
      dateRangeData,
      setDateFormat,
      get
    };
  }
});
