var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-input',_vm._b({attrs:{"label":_vm.$attrs.label,"is-disable":_vm.isDisable,"rules":_vm.validationRules || {},"name":_vm.$attrs.name,"field-attrs":_vm.$attrs},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var defaultAttrs = ref.defaultAttrs;
var clearError = ref.clearError;
return [_c('div',[(_vm.inputAttrs.outsideLabel)?_c('span',{staticClass:"tw-text-sm tw-text-enstack-black"},[_c('span',{domProps:{"textContent":_vm._s(_vm.labelPrefix)}}),_vm._v(" Date ")]):_vm._e(),(_vm.mode.includes('date'))?_c('vc-date-picker',{attrs:{"model-config":_vm.modelConfig,"popover":{ visibility: 'click' },"is-range":""},on:{"input":function($event){return clearError(_vm.$attrs.name)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var inputValue = ref.inputValue;
var inputEvents = ref.inputEvents;
var togglePopover = ref.togglePopover;
return [_c('v-text-field',_vm._b({staticClass:"rounded-lg",staticStyle:{"font-size":"14px"},attrs:{"value":_vm.setDateFormat(inputValue),"error-messages":null,"error":!!defaultAttrs('input-field').errorMessages,"clearable":"","hide-details":true,"placeholder":_vm.$attrs.placeholder,"readonly":""},on:{"click:clear":function($event){_vm.dateRangeData = ''}},nativeOn:{"input":function($event){return inputEvents.input.apply(null, arguments)},"change":function($event){return inputEvents.change.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('app-svg-icon',{staticClass:"tw-mr-2 tw-cursor-pointer",attrs:{"name":"calendar","height":"22","width":"20","color":"#a1a1a1"},nativeOn:{"click":function($event){return togglePopover.apply(null, arguments)}}})]},proxy:true}],null,true)},'v-text-field',defaultAttrs('input-field'),false))]}}],null,true),model:{value:(_vm.dateRangeData),callback:function ($$v) {_vm.dateRangeData=$$v},expression:"dateRangeData"}}):_vm._e()],1)]}}])},'app-input',_vm.inputAttrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }