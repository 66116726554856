
















































































































































































































import {
  defineComponent,
  onMounted,
  PropType,
  ref,
  toRefs,
  unref,
  computed,
  watch,
  nextTick,
  reactive
} from "@vue/composition-api";
import { TableHeader } from "@/types/components/TableComponent";
import { intersection, get, debounce, difference } from "lodash";
import { withAsync } from "@/helpers";
import { DropdownItems } from "@/types/components/InputComponents";
interface ServerFetchFn {
  fn: Function;
  payload: Object;
}
export default defineComponent({
  props: {
    hasGap: {
      type: String
    },
    hasSearch: {
      type: String
    },
    headers: {
      type: Array as PropType<Array<TableHeader>>,
      required: true
    },
    dataset: {
      type: Array,
      default: () => []
    },
    dataKey: { type: String, default: "id" },
    dataProp: { type: String, default: "results" },
    serverFetch: Object as PropType<ServerFetchFn>,
    mode: {
      type: String,
      default: "data-table",
      validator: (value: string) =>
        ["data-table", "simple-table"].includes(value)
    },
    searchAttrs: Object,
    rowsPerPage: {
      type: Number,
      default: 10
    },
    selectRows: Boolean,
    selectedRowActions: {
      type: Array as PropType<Array<DropdownItems>>,
      default: () => []
    },
    initialOrdering: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const { mode, dataProp, dataKey, selectRows } = props;
    const {
      dataset,
      serverFetch = null,
      rowsPerPage,
      initialOrdering
    } = toRefs(props);
    // Ordering
    const sortedColumns = ref<string[]>([]);
    const index = (needle: string) => unref(sortedColumns).indexOf(needle);
    const isColumnIncluded = (values: string[]) => {
      return !!intersection(unref(sortedColumns), values).length;
    };
    const sortColumns = async (column: string) => {
      const desc = `-${column}`;
      if (!isColumnIncluded([column, desc])) sortedColumns.value.push(column);
      else {
        if (isColumnIncluded([desc]))
          sortedColumns.value.splice(index(desc), 1);
        else sortedColumns.value.splice(index(column), 1, desc);
      }
      emit("sort", unref(sortedColumns));
      if (mode === "data-table") getTableData();
    };
    const getValue = (header: TableHeader, data: Record<string, any>) => {
      const value =
        typeof header.field === "string"
          ? data[header.field]
          : header.field(data);
      return header.format?.(value, data) || value;
    };
    const page = ref<number>(1);
    const pagination = reactive({
      totalRows: 10,
      rowsPerPage,
      currentPage: 1,
      totalPage: 1
    });
    // Fetch
    const serverSideData = ref<Record<string, any>>();
    const refreshData = async () => {
      pagination.currentPage = 1;
      await getTableData();
    };
    const tableData = computed(() =>
      mode === "data-table"
        ? [...(unref(serverSideData)?.[dataProp] || [])]
        : [...unref(dataset)]
    );
    const getTableData = async ({ isResetSelectedRows = true } = {}) => {
      await nextTick();
      if (unref(serverFetch)?.fn) {
        const payload = {
          size: pagination.rowsPerPage,
          search: unref(search),
          ...unref(serverFetch)?.payload,
          ordering: (unref(sortedColumns).length
            ? unref(sortedColumns)
            : unref(initialOrdering)
          ).join(","),
          page: pagination.currentPage
        };
        if (isResetSelectedRows && selectRows) selectedRows.value = [];
        const { data } = await withAsync(unref(serverFetch)!.fn, payload);
        if (data) {
          serverSideData.value = { ...data };
          pagination.totalRows = data.total_count;
          pagination.totalPage = Math.ceil(
            data.total_count / pagination.rowsPerPage
          );
        }
      }
    };
    // Searching mechanism
    const search = ref<string>("");
    const debouncedInstance = debounce(async () => await getTableData(), 500);
    watch(search, async () => {
      debouncedInstance.cancel();
      await debouncedInstance();
    });
    const currentTableDataKeys = computed(() =>
      unref(tableData).map(data => data[dataKey])
    );
    const selectedRows = ref<string[]>([]);
    const hasSelectedRows = computed(() => !!unref(selectedRowCount));
    const selectedRowAction = ref<any>("");
    const selectedRowCount = computed(() => {
      const selected = intersection(
        unref(selectedRows),
        unref(currentTableDataKeys)
      );
      return selected.length;
    });
    const isIndeterminate = computed(() => {
      return (
        unref(hasSelectedRows) &&
        unref(selectedRowCount) < unref(currentTableDataKeys).length
      );
    });
    const onSelectAllRows = (selected: boolean) => {
      if (selected) {
        selectedRows.value = [
          ...new Set([...unref(currentTableDataKeys), ...selectedRows.value])
        ];
      } else {
        selectedRows.value = difference(
          selectedRows.value,
          unref(currentTableDataKeys)
        );
      }
    };
    const onSelectRowsAction = async () => {
      emit(unref(selectedRowAction), unref(selectedRows));
      await nextTick();
      selectedRowAction.value = "";
    };
    onMounted(async () => {
      await getTableData();
    });
    return {
      sortColumns,
      sortedColumns,
      isColumnIncluded,
      tableData,
      getTableData,
      getValue,
      pagination,
      refreshData,
      get,
      page,
      search,
      selectedRows,
      onSelectAllRows,
      hasSelectedRows,
      isIndeterminate,
      selectedRowAction,
      onSelectRowsAction
    };
  }
});
