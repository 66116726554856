




























































import { defineComponent, ref, computed } from "@vue/composition-api";
import { AppModal, AppInput } from "@/components/Common";

export default defineComponent({
  components: { AppModal },
  props: {
    title: String,
    titleClass: [String, Array],
    isConfirmActive: {
      type: Boolean,
      default: false, 
    },
    dividerColor: String,
    closeOnCancel: {
      type: Boolean,
      default: false, 
    },
    closeOnConfirm: {
      type: Boolean,
      default: false, 
    },
    isDefaultCancel: {
      type: Boolean,
      default: true, 
    },
    cancelAttr: [String, Object],
    confirmAttr: [String, Object]
  },
  setup(props, { emit }) {
    const transactionModal = ref<InstanceType<typeof AppModal> | null>(null);
    const formErrors = ref<any>({});

    const showModal = () => {
      transactionModal.value?.show();
    };
    const hideModal = () => {
      transactionModal.value?.hide();
    };

    const onConfirm = () => {
      emit("confirm");
      if (props.closeOnConfirm) hideModal();
    };
    
    const onCancel = () => {
      emit("cancel", hideModal);
      if (props.closeOnCancel) hideModal();
    };

    return { 
      showModal, 
      transactionModal,
      onCancel,
      onConfirm,
      formErrors,
    };
  }
});
