





























































import { 
  defineComponent, 
  ref, 
  computed, 
  reactive, 
  onMounted, 
  inject 
} from "@vue/composition-api";
import { 
  AppModal, 
  AppInput, 
  AppModalMultipleContent 
} from "@/components/Common";
import { withAsync, extractString } from "@/helpers";
import {
  addSingleTransaction,
  bulkUploadTransactions,
} from "@/api/transactionsAPI";
import SingleTransaction from "./SingleTransaction.vue";
import BulkTransactions from "./BulkTransactions.vue";

export default defineComponent({
  components: { AppModal, AppModalMultipleContent, SingleTransaction, BulkTransactions },
  setup() {
    const { showSnackbar, messages } = inject("snackbar") || {};

    const formData = reactive<Record<string, string | number | any[] | null>>({
      slugs: "",
      user: "",
      amount: "",
      description: "",
      status: "",
			created_at: "",
			updated_at: "",
      total_users: "",
      total_amount: "",
    });
    const formDataFile = reactive<Record<string, Blob | null>>({
      batchCSV: null
    });
        
    const mainContent = ref(true)
    const singleTransaction = ref(false)
    const singleTransactionSummary = ref(false)
    const bulkTransactions = ref(false)
    const bulkTransactionsSummary = ref(false)

    const isConfirmActive = computed(() => Boolean(
      singleTransaction.value 
      || singleTransactionSummary.value 
      || bulkTransactions.value 
      || bulkTransactionsSummary.value
    ))

    const resetModalContents = () => {
      mainContent.value = true
      singleTransaction.value = false
      singleTransactionSummary.value = false
      bulkTransactions.value = false
      bulkTransactionsSummary.value = false
    }

    const showSingleTransactionContent = () => {
      mainContent.value = false
      singleTransaction.value = true
      singleTransactionSummary.value = false
    }

    const showBulkTransactionContent = () => {
      mainContent.value = false
      bulkTransactions.value = true
      bulkTransactionsSummary.value = false
    }

    const showSingleTransactionSummary = () => {
      mainContent.value = false
      singleTransaction.value = false
      singleTransactionSummary.value = true
    }

    const resetFields = () => {
      formData.user = "";
      formData.amount = "";
      formData.description = "";
      formDataFile.batchCSV = null;
      formData.total_users = ""
      formData.total_amount = ""
    }

    const showBulkTransactionSummary = () => {
      mainContent.value = false
      bulkTransactions.value = false
      bulkTransactionsSummary.value = true
    }

    const confirmLabelAttr = computed(() => {
      if(singleTransaction.value) 
        return 'Add Transaction'
      if(bulkTransactions.value) 
        return 'Upload'
      return 'Confirm'
    })

    const onConfirm = async () => {
      const fn = singleTransactionSummary.value 
        ? addSingleTransaction : bulkUploadTransactions;

      if(singleTransactionSummary.value) {
        const payload =  {
          user: formData.user,
          amount: formData.amount,
          description: formData.description,
        }
        const { data, errors } = await apiRequest(fn, payload)

        if (errors) {
          return 
        } 

        formData.value = { ...data }
        
        resetModalContents()
        resetFields()
      }
      
      if(bulkTransactionsSummary.value) {
        resetModalContents()
        resetFields()
      }

      if(singleTransaction.value)
        showSingleTransactionSummary()

      if(bulkTransactions.value) {
        const messages = {
          success: "Parsing success",
          error: "Parsing data failed"
        }
        const payload = new FormData();
        payload.append("file", formDataFile.batchCSV || "");

        const { data, errors } = await apiRequest(fn, payload, messages)

        if (errors) {
          return
        } 

        const total_users = data.manual_transactions?.filter((record) => record.user).length
        const total_amount = ref<number>(0) 
          
        data.manual_transactions?.forEach((record) => total_amount.value += +record.amount)
        formData.total_users = total_users
        formData.total_amount = total_amount.value

        showBulkTransactionSummary()
      }
    }
    
    const onCancel = () => {
      resetModalContents()
      resetFields()
    }

    const apiRequest = async (fn, payload, message:any = {}) => {
      const { data, errors } = await withAsync(fn, payload);

      if (errors) {
        
        showSnackbar({
          type: "error",
          message: extractString(message.error ?? errors.non_field_errors)
        });
        return { data, errors }
      } 

      showSnackbar({ type: "success", message: message.success ?? messages.save });

      return { data, errors }
    }

    onMounted(() => resetFields())

    return {
      mainContent,
      singleTransaction,
      singleTransactionSummary,
      showSingleTransactionContent,
      showBulkTransactionContent,
      showSingleTransactionSummary,
      showBulkTransactionSummary,
      resetModalContents,
      onConfirm,
      onCancel,
      formData,
      formDataFile,
      confirmLabelAttr,
      bulkTransactions,
      bulkTransactionsSummary,
      isConfirmActive
    }
  }
})
