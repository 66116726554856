export const SNACKBAR_DEFAULT_MESSAGES = <Record<string, string>>{
  save: "Saved successfully.",
  cancel: "Cancelled successfully.",
  update: "Updated successfully.",
  upload: "Uploaded."
};

export const DIALOG_TYPES = <Record<string, any>>{
  SAVE_CHANGES: {
    title: "Save Changes",
    content:
      "All changes will be saved and will be reflected in the App. All \
  previous withdrawal request will not be affected.",
    confirmAttrs: { label: "Save" },
    cancelAttrs: { label: "Back" }
  },
  CANCEL_CHANGES: {
    title: "Cancel Changes",
    content:
      "Are you sure you want to cancel? You have unsaved changes that will be reverted.",
    confirmAttrs: { label: "Cancel", color: "enstack-red", dark: true },
    cancelAttrs: { label: "Back" }
  }
};

export const TIMEZONE = "Asia/Manila";
