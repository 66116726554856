import api from "./api";

const URLS = {
  getTransactionsList: "/api/wallet/admin/manual-transaction/",
  approveTransaction: "/api/wallet/admin/manual-transaction/approve/",
  cancelTransaction: "/api/wallet/admin/manual-transaction/cancel/",
  addSingleTransaction: "/api/wallet/admin/manual-transaction/to-pending/",
  bulkUploadTransactions: "/api/wallet/admin/bulk/manual-transaction/to-pending/",
};

export const getTransactionsList = params =>
  api.get(URLS.getTransactionsList, { params });

export const approveTransaction = payload =>
  api.post(URLS.approveTransaction, payload);

export const cancelTransaction = payload => api.post(URLS.cancelTransaction, payload);

export const addSingleTransaction = payload =>
  api.post(URLS.addSingleTransaction, payload);
  
export const bulkUploadTransactions = payload =>
  api.post(URLS.bulkUploadTransactions, payload, {
    headers: { "Content-Type": "multipart/form-data" }
  });
