

































import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "button",
      validator: (value: string) => ["button", "text"].includes(value)
    },
    label: String,
    color: {
      type: String,
      default: "secondary"
    }
  },
  setup() {
    return {};
  }
});
