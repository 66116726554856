








import { defineComponent, provide, ref, unref } from "@vue/composition-api";
import { UnleashClient } from "unleash-proxy-client";
import { AppSnackbar } from "@/components/Common";
import { SNACKBAR_DEFAULT_MESSAGES } from "@/constants/appConstants";
import { SnackbarConfig } from "@/types/components/SnackbarComponent";

export default defineComponent({
  components: { AppSnackbar },
  setup() {
    const isShowSnackbar = ref<boolean>(false);
    const snackbarConfig = ref<Record<string, string>>({
      type: "info",
      message: ""
    });

    const showSnackbar = ({ type = "info", message = "" }: SnackbarConfig) => {
      snackbarConfig.value = Object.assign({}, { type, message });

      if (message) isShowSnackbar.value = true;
    };

    provide("snackbar", { showSnackbar, messages: SNACKBAR_DEFAULT_MESSAGES });
    
    // TODO: Proper structure
    const config = {
      url: process.env.VUE_APP_UNLEASH_API_URL || "",
      clientKey: process.env.VUE_APP_UNLEASH_CLIENT_KEY || "",
      refreshInterval: parseInt(process.env.VUE_APP_UNLEASH_REFRESH_INTERVAL || "5"),
      appName: "Enstack",
    };
    const client = new UnleashClient(config);
    client.start()
    provide("unleash", { unleash: client })

    return { isShowSnackbar, snackbarConfig };
  }
});
