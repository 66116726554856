










































import { defineComponent, PropType } from "@vue/composition-api";
import reusableComponent from "@/composables/reusableInputComponent";
import { RadioItems } from "@/types/components/InputComponents";

const { props, ...options } = reusableComponent;

export default defineComponent({
  ...options,
  props: {
    ...props,
    items: {
      type: Array as PropType<Array<RadioItems>>,
      default: () => []
    }
  }
});
