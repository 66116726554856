





























import {
  computed,
  defineComponent,
  PropType,
  ref,
  unref,
  watch
} from "@vue/composition-api";

interface TabItems {
  text: string;
  value: string | number;
}
export default defineComponent({
  props: {
    tabs: {
      type: Array as PropType<string[] | TabItems[]>,
      required: true
    },
    backgroundColor: {
      type: String,
      default: "transparent"
    },
    color: {
      type: String,
      default: "secondary"
    }
  },
  setup({ tabs = [] }, { root }) {
    const { $route } = root;
    const { hash = "" } = $route;
    // tabs' values will be converted into lowercase kebab-case format
    const computedTabs = computed(() =>
      tabs.map((tab: string | TabItems, index: number) => {
        return typeof tab === "string"
          ? {
              text: tab.toLowerCase(),
              value: tab.toLowerCase().replaceAll(" ", "-")
            }
          : {
              text: tab.text || `Tab ${index + 1}`,
              value: tab.value || `tab-${index + 1}`
            };
      })
    );
    const activeTab = ref<string>(
      hash.replace("#", "") || `${unref(computedTabs)?.[0]?.value} ` || ""
    );
    return { computedTabs, activeTab };
  }
});
